<template>
  <div>

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="elementData === undefined"
    >
      <h4 class="alert-heading">
        {{ $t(RESOURCES_ELEMENT_NAME + '.search.error.fetching_single_data')}}
      </h4>
      <div class="alert-body">
        {{ $t(RESOURCES_ELEMENT_NAME + '.search.error.check')}}
        <b-link
          class="alert-link"
          :to="{ name: 'apps-'+RESOURCES_ELEMENT_NAME+'-list'}"
        >
        {{ $t(RESOURCES_ELEMENT_NAME + '.search.error.list')}}
        </b-link>
        {{ $t(RESOURCES_ELEMENT_NAME + '.search.error.to_find_other')}}
      </div>
    </b-alert>


    <template v-if="elementData">
      <element-edit-tab
        :new-element="newElement"
        :element-data="elementData"
        :service-type-list="serviceTypeList"
        :units-of-measure-list="unitsOfMeasureList"
        :administration-route-list="administrationRouteList"
        :reference-unit-list="referenceUnitList"
        :dosage-form-list="dosageFormList"
        :medicine-group-list="medicineGroupList"
        :delivery-unit-list="deliveryUnitList"
        :boolean-list="booleanList"
        :resources-element-name="RESOURCES_ELEMENT_NAME"
        class="mt-2 pt-75"
      />
    </template>
    
  </div>
</template>

<script>
import {
   BAlert, BLink
} from 'bootstrap-vue'
import router from '@/router'
import store from '@/store'
import elementStoreModule from '../storeModule'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ElementEditTab from './EditCard.vue'

export default {
  components: {
    BAlert,
    BLink,
    ElementEditTab: ElementEditTab,
  },
  data() {
    return {
      RESOURCES_ELEMENT_NAME: '',
      ELEMENT_APP_STORE_MODULE_NAME: '',
      newElement: true,
      elementData: {},
      unitsOfMeasureList: [],
      administrationRouteList: [],
      referenceUnitList: [],
      dosageFormList: [],
      medicineGroupList: [],
      deliveryUnitList: [],
      booleanList: [],
      serviceTypeList: []
    }
  },
  async mounted() {
    this.RESOURCES_ELEMENT_NAME = 'services'
    this.ELEMENT_APP_STORE_MODULE_NAME = 'app-ser-ed-' + this.RESOURCES_ELEMENT_NAME
    // Register module
    if (!store.hasModule(this.ELEMENT_APP_STORE_MODULE_NAME)) store.registerModule(this.ELEMENT_APP_STORE_MODULE_NAME, elementStoreModule)
    await this.fetchServiceTypes()

    this.administrationRouteList = await this.fetch_key_value('administration_route')
    this.booleanList = await this.fetch_key_value('boolean')
    this.unitsOfMeasureList = await this.fetch_key_value('units_of_measure')
    this.referenceUnitList = await this.fetch_key_value('reference_unit')
    this.dosageFormList = await this.fetch_key_value('dosage_form')
    this.medicineGroupList = await this.fetch_key_value('medicine_group')
    this.deliveryUnitList = await this.fetch_key_value('delivery_unit')
    
    if (router.currentRoute.params.id !== undefined) {
      await this.fetchElement()
    } else {
      this.elementData = {status: 1}
    }
    
  },
  unmounted() {
    // UnRegister on leave
    if (store.hasModule(this.ELEMENT_APP_STORE_MODULE_NAME)) store.unregisterModule(this.ELEMENT_APP_STORE_MODULE_NAME)
  },
  methods: {
    async fetch_key_value(type) {
      return store.dispatch(this.ELEMENT_APP_STORE_MODULE_NAME + '/fetch_key_value', { type: type }).then(response => {
        var list = response.data.items
        list.unshift({ id: null, value: this.$t('list.defaultOption') })
        return list
      }).catch(error => {
        if (error.response.status === 404) {
          return undefined
        }
      })
    },
    fetchServiceTypes() {
      store.dispatch(this.ELEMENT_APP_STORE_MODULE_NAME + '/fetchServiceTypes')
      .then(response => { 
        this.serviceTypeList = response.data.items 
        this.serviceTypeList.unshift({ id: null, value: this.$t('list.defaultOption') })
      })
      .catch(error => {
        if (error.response.status === 404) {
          this.idTypeList = undefined
        }
      })
    },
    fetchElement() {
      this.newElement = false
      store.dispatch(this.ELEMENT_APP_STORE_MODULE_NAME + '/fetch_element', { id: router.currentRoute.params.id })
        .then(response => { 
          this.elementData = response.data 
          if (this.elementData.logo_id) {
            this.fetchLogo(this.elementData.logo_id)
          }
        })
        .catch(error => {
          if (error.response.status === 404) {
            this.elementData = undefined
          }
        })

    },
    save(elementData) {
      let method = this.ELEMENT_APP_STORE_MODULE_NAME + (this.newElement ? '/add_element' : '/save_element')
      let action = this.newElement ? 'creation' : 'update'
      this.newElement = false
      var _self = this
      store
        .dispatch(method, {data: elementData})
        .then(response => {
          _self.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: _self.$t(_self.RESOURCES_ELEMENT_NAME + '.action_results.'+action+'.title'),
              icon: 'SaveIcon',
              variant: 'success',
              text: _self.$t(_self.RESOURCES_ELEMENT_NAME + '.action_results.'+action+'.success'),
            },
          })
          _self.$router.replace({ name: 'apps-'+_self.RESOURCES_ELEMENT_NAME+'-list' })
        })
        .catch((error) => {
          _self.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: _self.$t(_self.RESOURCES_ELEMENT_NAME + '.action_results.'+action+'.title'),
              icon: 'SaveIcon',
              variant: 'danger',
              text: _self.$t(_self.RESOURCES_ELEMENT_NAME + '.action_results.'+action+'.failure'),
            },
          })
        })
    }
  }
}
</script>

<style>

</style>
