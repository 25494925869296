var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.save($event)}}},[_c('validation-observer',{ref:"elementForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-card',[_c('b-card-header',[_c('div',{staticClass:"text-primary h2"},[_c('feather-icon',{staticClass:"mr-0 mr-sm-50",attrs:{"icon":"StarIcon","size":"25","variant":"primary"}}),_c('span',{staticClass:"d-none d-sm-inline"},[_vm._v(_vm._s(_vm.$t(_vm.resourcesElementName + '.object.service_type')))])],1)]),_c('b-card-body',[_c('b-row',[_c('b-col',{attrs:{"cols":"3","md":"3"}},[_c('b-form-group',{attrs:{"label-for":"service_type"}},[_c('feather-icon',{staticClass:"mr-75",attrs:{"icon":"StarIcon"}}),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t(_vm.resourcesElementName + '.object.service_type')))]),_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_c('validation-provider',{attrs:{"name":_vm.$t(_vm.resourcesElementName + '.object.service_type'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.serviceTypeList,"label":"value","value":"id","reduce":function (option) { return option.id; }},on:{"input":_vm.onTypeChange},model:{value:(_vm.elementData.service_type_id),callback:function ($$v) {_vm.$set(_vm.elementData, "service_type_id", $$v)},expression:"elementData.service_type_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1)],1)],1),(_vm.elementData.service_type)?_c('b-card',[_c('b-card-header',[_c('div',{staticClass:"text-primary h2"},[_c('feather-icon',{staticClass:"mr-0 mr-sm-50",attrs:{"icon":"StarIcon","size":"25","variant":"primary"}}),_c('span',{staticClass:"d-none d-sm-inline"},[_vm._v("Información Principal")])],1)]),(_vm.elementData.service_type.key=='MEDICINES')?_c('b-card-body',[_c('b-row',[_c('b-col',{attrs:{"cols":"6","xl":"6"}},[_c('b-form-group',{attrs:{"label-for":"name"}},[_c('feather-icon',{staticClass:"mr-75",attrs:{"icon":"StarIcon"}}),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t(_vm.resourcesElementName + '.object.name')))]),_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_c('validation-provider',{attrs:{"name":_vm.$t(_vm.resourcesElementName + '.object.name'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","maxlength":"100"},model:{value:(_vm.elementData.name),callback:function ($$v) {_vm.$set(_vm.elementData, "name", $$v)},expression:"elementData.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"6","xl":"6"}},[_c('b-form-group',{attrs:{"label-for":"generic_name"}},[_c('feather-icon',{staticClass:"mr-75",attrs:{"icon":"StarIcon"}}),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t(_vm.resourcesElementName + '.object.generic_name')))]),_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_c('validation-provider',{attrs:{"name":_vm.$t(_vm.resourcesElementName + '.object.generic_name'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"generic_name","maxlength":"100"},model:{value:(_vm.elementData.cups_name),callback:function ($$v) {_vm.$set(_vm.elementData, "cups_name", $$v)},expression:"elementData.cups_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"6","md":"6"}},[_c('b-form-group',{attrs:{"label-for":"medicine_group"}},[_c('feather-icon',{staticClass:"mr-75",attrs:{"icon":"StarIcon"}}),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t(_vm.resourcesElementName + '.object.medicine_group')))]),_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_c('validation-provider',{attrs:{"name":_vm.$t(_vm.resourcesElementName + '.object.medicine_group'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.medicineGroupList,"label":"value","value":"id","reduce":function (option) { return option.id; },"placeholder":_vm.$t('list.defaultOption')},model:{value:(_vm.elementData.medicine_group_id),callback:function ($$v) {_vm.$set(_vm.elementData, "medicine_group_id", $$v)},expression:"elementData.medicine_group_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"6","xl":"6"}},[_c('b-form-group',{attrs:{"label-for":"laboratory"}},[_c('feather-icon',{staticClass:"mr-75",attrs:{"icon":"StarIcon"}}),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t(_vm.resourcesElementName + '.object.laboratory')))]),_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_c('validation-provider',{attrs:{"name":_vm.$t(_vm.resourcesElementName + '.object.laboratory'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"laboratory","maxlength":"100"},model:{value:(_vm.elementData.laboratory),callback:function ($$v) {_vm.$set(_vm.elementData, "laboratory", $$v)},expression:"elementData.laboratory"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"4","xl":"4"}},[_c('b-form-group',{attrs:{"label-for":"healt_registry"}},[_c('feather-icon',{staticClass:"mr-75",attrs:{"icon":"StarIcon"}}),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t(_vm.resourcesElementName + '.object.healt_registry')))]),_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_c('validation-provider',{attrs:{"name":_vm.$t(_vm.resourcesElementName + '.object.healt_registry'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"healt_registry","maxlength":"100"},model:{value:(_vm.elementData.healt_registry),callback:function ($$v) {_vm.$set(_vm.elementData, "healt_registry", $$v)},expression:"elementData.healt_registry"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"4","xl":"4"}},[_c('b-form-group',{attrs:{"label-for":"cum_file"}},[_c('feather-icon',{staticClass:"mr-75",attrs:{"icon":"StarIcon"}}),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t(_vm.resourcesElementName + '.object.cum_file')))]),_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_c('validation-provider',{attrs:{"name":_vm.$t(_vm.resourcesElementName + '.object.cum_file'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"cum_file","maxlength":"100"},model:{value:(_vm.elementData.cups),callback:function ($$v) {_vm.$set(_vm.elementData, "cups", $$v)},expression:"elementData.cups"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"4","xl":"4"}},[_c('b-form-group',{attrs:{"label-for":"cum_quantity"}},[_c('feather-icon',{staticClass:"mr-75",attrs:{"icon":"StarIcon"}}),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t(_vm.resourcesElementName + '.object.cum_quantity')))]),_c('validation-provider',{attrs:{"name":_vm.$t(_vm.resourcesElementName + '.object.cum_quantity')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"cum_quantity","maxlength":"10","pattern":"^[0-9]*$"},model:{value:(_vm.elementData.cum_quantity),callback:function ($$v) {_vm.$set(_vm.elementData, "cum_quantity", $$v)},expression:"elementData.cum_quantity"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"4","xl":"4"}},[_c('b-form-group',{attrs:{"label-for":"medicine_description"}},[_c('feather-icon',{staticClass:"mr-75",attrs:{"icon":"StarIcon"}}),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t(_vm.resourcesElementName + '.object.medicine_description')))]),_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_c('validation-provider',{attrs:{"name":_vm.$t(_vm.resourcesElementName + '.object.medicine_description'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"medicine_description","maxlength":"100"},model:{value:(_vm.elementData.description),callback:function ($$v) {_vm.$set(_vm.elementData, "description", $$v)},expression:"elementData.description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"4","md":"4"}},[_c('b-form-group',{attrs:{"label-for":"administration_route"}},[_c('feather-icon',{staticClass:"mr-75",attrs:{"icon":"StarIcon"}}),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t(_vm.resourcesElementName + '.object.administration_route')))]),_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_c('validation-provider',{attrs:{"name":_vm.$t(_vm.resourcesElementName + '.object.administration_route'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.administrationRouteList,"label":"value","value":"id","reduce":function (option) { return option.id; },"placeholder":_vm.$t('list.defaultOption')},model:{value:(_vm.elementData.administration_route_id),callback:function ($$v) {_vm.$set(_vm.elementData, "administration_route_id", $$v)},expression:"elementData.administration_route_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"4","xl":"4"}},[_c('b-form-group',{attrs:{"label-for":"active_principle"}},[_c('feather-icon',{staticClass:"mr-75",attrs:{"icon":"StarIcon"}}),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t(_vm.resourcesElementName + '.object.active_principle')))]),_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_c('validation-provider',{attrs:{"name":_vm.$t(_vm.resourcesElementName + '.object.active_principle'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"active_principle","maxlength":"100"},model:{value:(_vm.elementData.active_principle),callback:function ($$v) {_vm.$set(_vm.elementData, "active_principle", $$v)},expression:"elementData.active_principle"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"4","md":"4"}},[_c('b-form-group',{attrs:{"label-for":"units_of_measure_id"}},[_c('feather-icon',{staticClass:"mr-75",attrs:{"icon":"StarIcon"}}),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t(_vm.resourcesElementName + '.object.units_of_measure')))]),_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_c('validation-provider',{attrs:{"name":_vm.$t(_vm.resourcesElementName + '.object.units_of_measure'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.unitsOfMeasureList,"label":"value","value":"id","reduce":function (option) { return option.id; },"placeholder":_vm.$t('list.defaultOption')},model:{value:(_vm.elementData.units_of_measure_id),callback:function ($$v) {_vm.$set(_vm.elementData, "units_of_measure_id", $$v)},expression:"elementData.units_of_measure_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"4","xl":"4"}},[_c('b-form-group',{attrs:{"label-for":"quantity"}},[_c('feather-icon',{staticClass:"mr-75",attrs:{"icon":"StarIcon"}}),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t(_vm.resourcesElementName + '.object.quantity')))]),_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_c('validation-provider',{attrs:{"name":_vm.$t(_vm.resourcesElementName + '.object.quantity'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"quantity","maxlength":"10","pattern":"^[0-9]*$"},model:{value:(_vm.elementData.quantity),callback:function ($$v) {_vm.$set(_vm.elementData, "quantity", $$v)},expression:"elementData.quantity"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"4","md":"4"}},[_c('b-form-group',{attrs:{"label-for":"reference_unit"}},[_c('feather-icon',{staticClass:"mr-75",attrs:{"icon":"StarIcon"}}),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t(_vm.resourcesElementName + '.object.reference_unit')))]),_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_c('validation-provider',{attrs:{"name":_vm.$t(_vm.resourcesElementName + '.object.reference_unit'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.referenceUnitList,"label":"value","value":"id","reduce":function (option) { return option.id; },"placeholder":_vm.$t('list.defaultOption')},model:{value:(_vm.elementData.reference_unit_id),callback:function ($$v) {_vm.$set(_vm.elementData, "reference_unit_id", $$v)},expression:"elementData.reference_unit_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"4","md":"4"}},[_c('b-form-group',{attrs:{"label-for":"dosage_form"}},[_c('feather-icon',{staticClass:"mr-75",attrs:{"icon":"StarIcon"}}),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t(_vm.resourcesElementName + '.object.dosage_form')))]),_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_c('validation-provider',{attrs:{"name":_vm.$t(_vm.resourcesElementName + '.object.dosage_form'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.dosageFormList,"label":"value","value":"id","reduce":function (option) { return option.id; },"placeholder":_vm.$t('list.defaultOption')},model:{value:(_vm.elementData.dosage_form_id),callback:function ($$v) {_vm.$set(_vm.elementData, "dosage_form_id", $$v)},expression:"elementData.dosage_form_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"4","md":"4"}},[_c('b-form-group',{attrs:{"label-for":"regulated"}},[_c('feather-icon',{staticClass:"mr-75",attrs:{"icon":"StarIcon"}}),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t(_vm.resourcesElementName + '.object.regulated')))]),_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_c('validation-provider',{attrs:{"name":_vm.$t(_vm.resourcesElementName + '.object.regulated'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.booleanList,"label":"value","value":"id","reduce":function (option) { return option.id; },"placeholder":_vm.$t('list.defaultOption')},model:{value:(_vm.elementData.regulated_id),callback:function ($$v) {_vm.$set(_vm.elementData, "regulated_id", $$v)},expression:"elementData.regulated_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"4","md":"4"}},[_c('b-form-group',{attrs:{"label-for":"minimum_delivery_unit"}},[_c('feather-icon',{staticClass:"mr-75",attrs:{"icon":"StarIcon"}}),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t(_vm.resourcesElementName + '.object.minimum_delivery_unit')))]),_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_c('validation-provider',{attrs:{"name":_vm.$t(_vm.resourcesElementName + '.object.minimum_delivery_unit'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.deliveryUnitList,"label":"value","value":"id","reduce":function (option) { return option.id; },"placeholder":_vm.$t('list.defaultOption')},model:{value:(_vm.elementData.minimum_delivery_unit_id),callback:function ($$v) {_vm.$set(_vm.elementData, "minimum_delivery_unit_id", $$v)},expression:"elementData.minimum_delivery_unit_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1)],1):_c('b-card-body',[_c('b-row',[_c('b-col',{attrs:{"cols":"3","xl":"3"}},[_c('b-form-group',{attrs:{"label-for":"intern_code"}},[_c('feather-icon',{staticClass:"mr-75",attrs:{"icon":"StarIcon"}}),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t(_vm.resourcesElementName + '.object.intern_code')))]),_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_c('validation-provider',{attrs:{"name":_vm.$t(_vm.resourcesElementName + '.object.intern_code'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"intern_code","maxlength":"100"},model:{value:(_vm.elementData.intern_code),callback:function ($$v) {_vm.$set(_vm.elementData, "intern_code", $$v)},expression:"elementData.intern_code"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"6","xl":"6"}},[_c('b-form-group',{attrs:{"label-for":"description"}},[_c('feather-icon',{staticClass:"mr-75",attrs:{"icon":"StarIcon"}}),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t(_vm.resourcesElementName + '.object.description')))]),_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_c('validation-provider',{attrs:{"name":_vm.$t(_vm.resourcesElementName + '.object.description'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"description","maxlength":"100"},model:{value:(_vm.elementData.description),callback:function ($$v) {_vm.$set(_vm.elementData, "description", $$v)},expression:"elementData.description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"3","xl":"3"}},[_c('b-form-group',{attrs:{"label-for":"cups"}},[_c('feather-icon',{staticClass:"mr-75",attrs:{"icon":"StarIcon"}}),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t(_vm.resourcesElementName + '.object.cups')))]),_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_c('validation-provider',{attrs:{"name":_vm.$t(_vm.resourcesElementName + '.object.cups'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"cups","maxlength":"100"},model:{value:(_vm.elementData.cups),callback:function ($$v) {_vm.$set(_vm.elementData, "cups", $$v)},expression:"elementData.cups"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"3","xl":"3"}},[_c('b-form-group',{attrs:{"label-for":"cups_name"}},[_c('feather-icon',{staticClass:"mr-75",attrs:{"icon":"StarIcon"}}),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t(_vm.resourcesElementName + '.object.cups_name')))]),_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_c('validation-provider',{attrs:{"name":_vm.$t(_vm.resourcesElementName + '.object.cups_name'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"cups_name","maxlength":"100"},model:{value:(_vm.elementData.cups_name),callback:function ($$v) {_vm.$set(_vm.elementData, "cups_name", $$v)},expression:"elementData.cups_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"3","xl":"3"}},[_c('b-form-group',{attrs:{"label-for":"idime_code"}},[_c('feather-icon',{staticClass:"mr-75",attrs:{"icon":"StarIcon"}}),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t(_vm.resourcesElementName + '.object.idime_code')))]),_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_c('validation-provider',{attrs:{"name":_vm.$t(_vm.resourcesElementName + '.object.idime_code'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"cups_name","maxlength":"10"},model:{value:(_vm.elementData.idime_code),callback:function ($$v) {_vm.$set(_vm.elementData, "idime_code", $$v)},expression:"elementData.idime_code"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"3","xl":"3"}},[_c('b-form-group',{attrs:{"label-for":"synlab_code"}},[_c('feather-icon',{staticClass:"mr-75",attrs:{"icon":"StarIcon"}}),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t(_vm.resourcesElementName + '.object.synlab_code')))]),_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_c('validation-provider',{attrs:{"name":_vm.$t(_vm.resourcesElementName + '.object.synlab_code'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"cups_name","maxlength":"10"},model:{value:(_vm.elementData.synlab_code),callback:function ($$v) {_vm.$set(_vm.elementData, "synlab_code", $$v)},expression:"elementData.synlab_code"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"6","md":"6","lg":"6"}},[_c('status-component',{attrs:{"element-data":_vm.elementData}})],1)],1)],1)],1):_vm._e(),_c('b-row',{staticClass:"d-flex justify-content-end"},[_c('b-button',{staticClass:"mb-1 mb-sm-0 mr-0 mr-sm-1",on:{"click":function($event){_vm.router().go(-1)}}},[_vm._v(" "+_vm._s(_vm.$t('actions.back'))+" ")]),_c('b-button',{staticClass:"mb-1 mb-sm-0 mr-0 mr-sm-1",attrs:{"variant":"primary","type":"submit","disabled":invalid}},[_vm._v(" "+_vm._s(_vm.$t('actions.save'))+" ")])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }