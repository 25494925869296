<template>
  <div>
    <b-form-group
      label-for="element-status"
    >
      <b-form-checkbox
        v-model="elementData.status"
        class="mr-0 mt-50"
        name="is-rtl"
        switch
        inline
        value="1"
        unchecked-value="0"
      />
      <h2 class="inline">
        <b-badge
          pill
          :variant="`light-${resolveUserStatusVariant(elementData.status)}`"
          class="text-capitalize mr-0 mt-50"
        >
          {{ resolveUserStatusMessage(elementData.status) }}
        </b-badge>
      </h2>
    </b-form-group>
  </div>
</template>

<script>
import {
  BFormGroup, BFormCheckbox, BBadge,
} from 'bootstrap-vue'

export default {
  components: {
    BFormGroup,
    BFormCheckbox,
    BBadge,
  },
  props: {
    elementData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    resolveUserStatusVariant(status) {
      if (status == 0) return 'warning'
      return 'success'
    },
    resolveUserStatusMessage(status) {
      if (status == 0) return this.$t('user.statusValue.inactive')
      return this.$t('user.statusValue.active')
    },
  },
}
</script>

<style>

</style>
